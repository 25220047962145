import React from "react";
import styles  from "./BoxRelated.module.scss";

const BoxRelated = ({item}) => {
    return (
        <ul className={styles.box}>
            <li className={styles.img}>
                <img src={item.imgUrl} />
            </li>
            <li className={styles.name}>{item.name}</li>
        </ul>
    );
}
 
export default BoxRelated;


