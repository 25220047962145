import React, { useState, useEffect } from "react";
import "./Result.scss";
import { Link} from "react-router-dom";
import BoxResult from "../../shared/BoxResult/BoxResult";
import useGetProducts from "../../hooks/useGetProducts";
import useGetSubCategories from "../../hooks/useGetSubCategories";
import useGetCategory from "../../hooks/useGetCategory";
import {useNavigate } from "react-router-dom";

const Result = ({typeOfItem,items,itemsUrl,postData}) => {

    const navigate = useNavigate ();
    
    const [productUrl , setProductUrl] = useState({});
    const [nameCateg, setNameCateg] = useState("");
    const[postDataSubC,setpostDataSubC] = useState({});
    
    const url = "https://www.obleasdulcesbsas.com.ar/api/getProduct.php";
    const {data:ProductosUrl, loading:loadingProductosUrl, error: errorProductosUrl} = useGetProducts(url,postData);

    const urlSubCategories = 'https://www.obleasdulcesbsas.com.ar/api/getSubCategory.php';
    const {data:subCategories, loading:loadingSubCategories, error: errorSubCategories} = useGetSubCategories(urlSubCategories,postDataSubC);

    const urlCategories = 'https://www.obleasdulcesbsas.com.ar/api/getCategory.php';
    const { data: categories, loading: loadingCategories, error: errorCategories } = useGetCategory(urlCategories, { "state": "A" });

    useEffect(() =>{
        console.log(itemsUrl);
        if(itemsUrl){

            itemsUrl.id_category?setpostDataSubC({"id_category":itemsUrl.id_category}):setpostDataSubC({"id_category":itemsUrl.id});
            itemsUrl.name_category ? setNameCateg(itemsUrl.name_category) : setNameCateg(itemsUrl.name);
            
        }else{
            setNameCateg("Todos los productos")
        }        
        if(ProductosUrl){setProductUrl(ProductosUrl)};
    }, [itemsUrl]);

    const handleClick = (route, item, type) => {
        let postData;
        
        if (type === "category") {
            postData = { "id_category": item.id };
        } else if(type === "subcategory") {
            postData = { "id_subcategory": item.id };
        }else{
            postData = { "state": "A" };
        }
        navigate(route, { state: { item, postData } });
    }

    return ( 
        <div className="container-result">
            {/*<div className="box-breadcurn">
                <p><Link to="/">BreadCurn</Link></p><span>/</span><p><Link to="/">BreadCurn</Link></p><span>/</span><p><Link to="/">BreadCurn</Link></p>
            </div>*/}
            <div className="box-filter">
                <h1>
                    {nameCateg}
                </h1>
                <ul>
                    {
                        itemsUrl?
                            Array.isArray(subCategories?.data) ?
                            subCategories.data.map((i, index) => (
                                <li key={index}>
                                    <a onClick={() => handleClick("/productos", i, "subcategory")}>{i.name}</a>
                                </li>
                            ))
                            :
                            <>no existen</>
                        :
                            Array.isArray(categories?.data) ?
                            categories.data.map((i, index) => (
                                <li key={index}>
                                    <a onClick={() => handleClick("/productos", i, "category")}>{i.name}</a>
                                </li>
                            ))
                            :
                            <>no existen</>
                    }
                </ul>
            </div>
            <div className="box-products">
                <h1>
                    {nameCateg}
                    <span>
                        (
                        {
                            Array.isArray(ProductosUrl?.data) ?
                            <>{ProductosUrl.data.length}</>
                            :
                            Array.isArray(items?.data) ?
                            <>{items.data.length}</>
                            :
                            <p>No items found</p>
                        }
                        )
                    </span>
                </h1>
                <div className="products">
                    {
                        Array.isArray(ProductosUrl?.data) ?
                            ProductosUrl.data.map((i, index) => (
                                <BoxResult
                                    key={index}
                                    className={typeOfItem}
                                    item={i}
                                />
                            )) 
                        :
                        Array.isArray(items?.data) ?
                            items.data.map((i, index) => (
                                <BoxResult
                                    key={index}
                                    className={typeOfItem}
                                    item={i}
                                />
                            )) :
                        <p>No items found</p>
                    }
                </div>
            </div>
        </div>
    );
}
 
export default Result;