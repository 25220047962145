import React,{useEffect} from "react";
import "./Alerta.scss";

const Alerta = ({tipo, mensaje, estadoAlerta, cambiarEstadoAlerta}) => {

    useEffect(() => {
        let tiempo;
        if(estadoAlerta === true){
            tiempo = setTimeout(() =>{
                cambiarEstadoAlerta(false);
            },3000)
        }
        return( () => clearTimeout(tiempo))
    }, [estadoAlerta , cambiarEstadoAlerta, tipo]);

    console.log(tipo);
    console.log(mensaje);
    console.log(estadoAlerta);

    return (
        <>
            {estadoAlerta &&
                <div tipo={tipo} className={"ContenedorAlerta " + tipo} >
                    <p className={tipo}> {mensaje} </p>
                </div>
            }
        </>
    );
}
 
export default Alerta;