// App.js
import React, { useContext  } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./components/views/Home/Home";
import Productos from "./components/views/Productos/Productos";
import Nosotros from "./components/views/Nosotros/Nosotros";
import Contacto from "./components/views/Contacto/Contacto";
import Layout from "./components/shared/Layout/Layout";
import Control from "./components/views/Control/Control";
import Productos_Control from "./components/views/Control/Productos_Control";
import Login from "./components/views/Control/Login";
import { AuthProvider  } from "./context/AuthContext";

import './App.scss';
import { Helmet } from 'react-helmet';

const App = () => {

  return (
    <AuthProvider>
      <BrowserRouter>
        <Helmet>
          <title>Obleas dulces Bs As</title>
          <meta name="description" content="Obleas dulces Buenoss Aires" />
          <meta name="keywords" content="Obleas, dulces, cubanitos" />
        </Helmet>
        
          <Layout>
            <Routes>
              <Route path="/login" element={<Login />} />          
              <Route path="/" element={<Home />} />
              <Route path="/inicio" element={<Home />} />
              <Route path="/productos" element={<Productos />} />
              <Route path="/nosotros" element={<Nosotros />} />
              <Route path="/contacto" element={<Contacto />} />
              <Route path="/Control" element={<Control />} />
              <Route path="/productos-control" element={<Productos_Control />} />   
            </Routes>
          </Layout>     
      </BrowserRouter>
    </AuthProvider>
  );
};

export default App;
