import React, {useState, useContext} from "react";
import { useNavigate } from "react-router-dom";
import Alerta from "../../elements/Alertas/Alertas";
import Btn from "../../elements/Btn/Btn";
import axios from "axios";
import { AuthContext } from "../../../context/AuthContext";
import "./template.scss";

const Login = () => {
    const navigate = useNavigate();
    const [usuario, establecerUsuario] = useState('');
    const [password, establecerPassword] = useState('');
    const [estadoAlerta, cambiarEstadoAlerta] = useState(false);
    const [alerta, cambiarAlerta] = useState({});
    const [data, setData] = useState(null);

    const urlLogin = 'https://obleasdulcesbsas.com.ar/api/login.php';
    let postData = {};

    const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext);

    const handleChange = (e) =>{
        if(e.target.name === 'usuario'){
            establecerUsuario(e.target.value);
        }else if(e.target.name === 'password'){
            establecerPassword(e.target.value);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        cambiarEstadoAlerta(false);
        cambiarAlerta({});

        try {
            if(usuario === '' || password === ''){
                cambiarEstadoAlerta(true);
                cambiarAlerta({
                    tipo: 'error',
                    mensaje: 'Completar todos los campos.'
                });
                return;
            }else{
                postData = {
                    "user":usuario,
                    "pass":password
                }
            }
            const response = await axios.post(urlLogin, postData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            setData(response.data);

            if(data.state == 200){
                cambiarEstadoAlerta(true);
                cambiarAlerta({tipo:'exito',mensaje: "Se inicio Sección con exito."});
                establecerUsuario('');
                establecerPassword('');
                setIsLoggedIn(true);
                console.log(isLoggedIn);
                navigate('/Control');
            }else{
                cambiarEstadoAlerta(true);
                cambiarAlerta({tipo:'error',mensaje: "Hubo un error al intentar enviar el mensaje."});
            }

            
        } catch (error) {
            cambiarAlerta({tipo:'error',mensaje: 'Verifique los datos.'});
        }
    }

    return ( 
        <>
            <form className="form-login" onSubmit={handleSubmit}>
                <input 
                    type="text"
                    name="usuario"
                    placeholder="Nombre Usuario"
                    value={usuario}
                    onChange={handleChange}
                />
                <input 
                    type="password"
                    name="password"
                    placeholder="Contraseña"
                    value={password}
                    onChange={handleChange}
                />
                <Btn 
                    cta
                    type="submit"
                    text="Iniciar Sesión" 
                />
            </form>
            <Alerta 
                tipoMsj={alerta.tipo}
                mensaje={alerta.mensaje}
                estadoAlerta={estadoAlerta}
                cambiarEstadoAlerta={cambiarEstadoAlerta}
            />
        </>
     );
}
 
export default Login;